$norton-blue: rgb(18, 20, 41);
$norton-yellow: rgb(240, 167, 36);
$norton-yellow-button-bg: rgb(252, 212, 76);
$mobile-max-width: 500px;

html,
body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 1rem;
}

@media screen and (max-width: $mobile-max-width) {
	/**
	* This is the primary app title only
	*/
	h1, .h1, .app-title-primary {
		font-size: 2.4rem;
	}

	/**
	 * This is the app subtitle only
	 */
	h2, .h2, .app-subtitle {
		font-size: 1.8rem;
	}

	/**
	* Styles the question subject on the quiz pane, e.g. "What action do you take?"
	*/
	.h3, h3 {
		font-size: 1.3rem;
	}

	/**
	* Styles email subject
	* Also styles title of questions in the survey pane
	*/
	h4, .h4 {
		font-size: 1.2rem;
	}

	.email-pane .email-subject {
		font-size: 1.15rem;
	}

	.quiz-question-display .quiz-question-title,
	.review-expl-pane .review-explanation-title {
		font-size: 1.27rem;
	}

	/**
	* No idea what this styles
	*/
	h5 {
		font-size: 1.1rem;
	}

	/**
	* Used to style the email to and from fields
	* Also the radio button labels in the Survey
	* Also the correct label during the Quiz (review pane)
	*/
	h6 {
		font-size: 1rem;
	}

	.btn-lg {
		font-size: 1.1rem;
	}
}

.wrapper {
	// sticky footer CSS trick
    min-height: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.quiz-header {
	@media screen and (min-width: $mobile-max-width) {
		&.quiz-header-lg {
			padding-bottom: 1rem;
		}
	}

    .app-title-primary,
    .app-subtitle {
        color: $norton-blue;
    }
}

button.norton-style-btn {
    background-color: $norton-yellow-button-bg;
    color: black;
    border-color: black;
    &:hover,
    &:focus,
    &:active {
        background-color: $norton-blue;
        color: $norton-yellow;
        border-color: black;
    }
    &:disabled {
        background-color: $norton-blue !important;
        color: white;
        border-color: black;
    }
}

button.norton-style-outline-btn {
    color: $norton-blue;
    border-color: $norton-blue;
}

/**
 * All the long explanations should be covered by these selectors
 */
.lang-select-pane p,
.pre-welcome-pane p,
.welcome-pane p,
.resume-pane p,
p.quiz-question-flavor-text,
p.quiz-question-context-title,
p.review-explanation-text,
.quiz-done-pane p,
.review-done-pane p,
.survey-pane p,
.thanks-pane p {
    font-size: 1.25rem;

	@media screen and (max-width: $mobile-max-width) {
		& {
			font-size: 1.13rem;
		}
	}
}

/**
 * Make this a little smaller than the p text above on big screens otherwise it looks weird
 */
p.review-explanation-text {
	font-size: 1.15rem;

	@media screen and (max-width: $mobile-max-width) {
		& {
			font-size: 1.05rem;
		}
	}
}

footer {
    padding-bottom: .5rem;
    text-align: center;

	@media screen and (max-width: $mobile-max-width) {
		& {
			padding-top: .5rem;
		}
	}
}

.iframe__container--height {
    min-height: 500px;
    height: auto;
}

.user-feedback-box-container,
.user-email-enrollment-form {
	border-top: 1px solid #ccc;
	padding-top: 1.5rem;
	max-width: 768px;
	padding-bottom: 1.5rem;
}

@media all and (min-height: 700px) {
    .iframe__container--height {
        min-height: 75vh;
        height: auto;
    }
}

@media all and (min-height: 1024px) {
    .iframe__container--height {
        min-height: 50vh;
        height: auto;
    }
    .max-width-50 {
        max-width: 50vw;
    }
}